import styled from "styled-components"
import { scale } from "../../utilities/typography"

const H1 = styled.h1`
  ${scale(1.25)};
  @media (min-width: ${(props) => props.theme.breakpoints.small}px) {
    ${scale(1.5)};
  }
  font-weight: 500;
  margin: 0;
`

export default H1
