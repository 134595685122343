import { graphql } from "gatsby"
import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { rhythm, scale } from "../utilities/typography"
import styled from "styled-components"
import { Typography } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import format from "date-fns/format"
import _ from "lodash/fp"
import NavigationLayout, { Content } from "../components/layout/mainLayout"
import parseISO from "date-fns/parseISO"
import rendererOptions from "../utilities/rendererOptions"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { de } from "date-fns/locale"
import H1 from "../components/typography/H1"

const Article = styled(Content)`
  max-width: ${(props) => props.theme.contentWidthReading};
  & > * {
    margin-bottom: ${rhythm(2)};
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`

const ArticleDate = styled.p`
  display: flex;
  color: ${(props) => props.theme.colors_rgba.black_medium};
  align-items: center;
`

const ArticleHeader = styled.section`
  & > * {
    margin-bottom: ${rhythm(1 / 2)};
  }
`

const ArticleContent = styled.section`
  & > h1 {
    ${scale(0.75)};
  }
`

const Backdrop = styled.section`
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
  padding-top: ${(props) => props.theme.navbarHeight};
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
`

const Post = ({
  data: {
    contentfulPost: { title, heroImage, body, date },
  },
}) => {
  const parsedDate = date ? parseISO(date) : null
  const formattedDate = format(parsedDate, "dd/MM/yyyy", {
    locale: de,
  })

  const content = body ? renderRichText(body, rendererOptions) : null

  return (
    <NavigationLayout>
      <Backdrop />
      <Article as="article">
        <ArticleHeader>
          {formattedDate && <ArticleDate>{formattedDate}</ArticleDate>}
          <H1>{title}</H1>
          {heroImage && (
            <GatsbyImage
              image={getImage(heroImage)}
              title={heroImage.title}
              alt={heroImage.description}
            />
          )}
          {_.get(["description"], heroImage) && (
            <Box m={1}>
              <Typography variant={"body2"}>
                {_.get(["description"], heroImage)}
              </Typography>
            </Box>
          )}
        </ArticleHeader>
        <ArticleContent>{content}</ArticleContent>
      </Article>
    </NavigationLayout>
  )
}

export const query = graphql`
  query ($urlTitle: String!) {
    contentfulPost(urlTitle: { eq: $urlTitle }) {
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
          }
          contentful_id
          title
          description
          fixed {
            src
          }
        }
      }
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
        title
        description
      }
      date
      title
      urlTitle
    }
  }
`

export default Post
